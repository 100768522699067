/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, createRef, useState } from "react";
import MaterialTablePicker from "components/MaterialTablePicker";
// import PropTypes from "prop-types";

import { useDispatch, useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/Cancel";
import OfflinePinIcon from "@mui/icons-material/OfflinePin";
// import DangerousIcon from "@mui/icons-material/Dangerous";
// import VerifiedIcon from "@mui/icons-material/Verified";
// import PendingIcon from "@mui/icons-material/Pending";
import Grid from "@mui/material/Grid2";

// Store

import setAlert from "store/actions/alertActions";
import {
  deleteTicket,
  updateTicket,
  clearErrors,
  getItemTickets,
  deleteSingleItem,
  // reject as rejectItem,
  // approve,
  // checkTicket,
  setItemEmpty,
  updateTicketType,
  topFalseFn,
  topTrueFn,
} from "store/actions/itemAction";
import { getUserPartnerLocations, setLoading, setLocation } from "store/actions/locationAction";
import DashboardLayout from "layouts/DashboardLayout";
import Footer from "components/Footer";
import DashboardNavbar from "components/DashboardNavbar";
import MDBox from "components/MDBox";
import DynamicTabs from "components/DynamicTabs";
import LocationUserPartner from "components/LocationUserPartner";
import { clearFilters } from "store/actions/userActions";
import columnsMap from "components/MaterialTablePicker/TableColumns";
// import form from "pages/items/tickets/new-ticket/schemas/form";
// import { getPdf, getCsv } from "store/actions/exportAction";
// import MDButton from "components/MDButton";

const Tickets = () => {
  //   const { location } = form.formField;

  const dispatch = useDispatch();
  const tableRef = createRef();

  const loading = useSelector((state) => state.items.loading);
  const error = useSelector((state) => state.items.error);
  const user = useSelector((state) => state.users.user);
  const ticket = useSelector((state) => state.items.item);
  const locations = useSelector((state) => state.locations.locations);
  const locationPartner = useSelector((state) => state.locations.location);
  const added = useSelector((state) => state.items.added);

  const [tabValue, setTabValue] = useState(0);
  const ticketType = useSelector((state) => state.items.ticketType);
  const [onChangeLocationID, setOnChangeLocationID] = useState(false);

  // const itemsExp = useSelector((state) => state.items.itemsExp);

  //   const [onChangeLocationID, setOnChangeLocationID] = useState(false);
  const [locationId, setLocationId] = useState(locations[0]?._id);
  const [click, setClick] = useState(false);
  // const [anchorEl, setAnchorEl] = React.useState(null);
  // const [open, setOpen] = React.useState(false);
  // const [joinedTitles, setJoinedTitles] = useState("");
  // const [rowDataTicket, setRowDataTicket] = useState({});

  const handleChange = (option, val) => {
    if (ticket?.location?._id) dispatch(deleteSingleItem());
    if (locationPartner) dispatch(deleteSingleItem());

    tableRef.current.onQueryChange();
    setOnChangeLocationID(true);
    setLocationId(val?._id);
    dispatch(setLocation(val?._id));
    getItemTickets(val?._id);
  };

  useEffect(() => {
    dispatch(setItemEmpty());
    if (user?.role === "partner") {
      dispatch(setLoading());
      dispatch(getUserPartnerLocations());
    }
  }, []);

  useEffect(() => {
    if (ticketType?.type === "viator") {
      setTabValue(1);
    } else {
      setTabValue(0);
    }
  }, [ticketType?.type, added]);

  useEffect(() => {
    if (locations?.length > 0 && !ticket?.location?._id) {
      setLocationId(locations[0]?._id);
    }
    // eslint-disable-next-line
  }, [locations]);

  // to add use Effect for set locaton ID when navigating back from edit-ticket
  useEffect(() => {
    if (ticket?.location?._id && locationId !== ticket?.location?._id) {
      setLocationId(ticket?.location?._id);
      dispatch(setLocation(locationId || ticket?.location?._id));
      getItemTickets(locationId || ticket?.location?._id);
    }
  }, [ticket?.location?._id, locationId]);

  useEffect(() => {
    if (click) {
      tableRef.current.onQueryChange();
      setClick(false);
    }
  }, [click]);

  useEffect(() => {
    dispatch(clearFilters());
  }, [tabValue]);

  // to add use Effect for set locaton ID when navigating back from new-ticket
  useEffect(() => {
    if (locationPartner && locationId !== locationPartner) {
      setLocationId(locationPartner);
      dispatch(setLocation(locationPartner));
      getItemTickets(locationPartner);
    }
  }, [locationPartner, locationId]);

  // useEffect(() => {
  //   if (Array.isArray(itemsExp)) {
  //     const titlesExp = itemsExp.map((obj) => obj.title);
  //     const joinedString = titlesExp.join(",");
  //     setJoinedTitles(joinedString);
  //   }
  // }, [itemsExp?.length]);

  useEffect(() => {
    if (error) {
      dispatch(setAlert(error, "error"));
      dispatch(clearErrors());
    }
  }, [error, setAlert]);

  // const handleCloseDialog = () => {
  //   setOpen(false);
  // };

  // const handleOpenDialog = () => {
  //   setOpen(true);
  // };

  const deleteTicketFn = (oldData) => dispatch(deleteTicket(oldData?._id));
  //   new Promise((resolve, reject) => {
  //     dispatch(checkTicket(oldData._id, oldData?.geoLocationData.city))
  //       .then(() => {
  //         handleOpenDialog();
  //         setRowDataTicket(oldData);
  //         resolve();
  //       })
  //       .catch((errorMsg) => {
  //         // eslint-disable-next-line no-console
  //         console.log(errorMsg.message);
  //         reject();
  //       });
  //   });

  const handleFalseTop = (id) => {
    dispatch(setLoading());
    dispatch(topFalseFn(id));
    setClick(true);
  };
  const handleTopTrue = (id) => {
    dispatch(setLoading());
    dispatch(topTrueFn(id));
    setClick(true);
  };

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === 0) {
      dispatch(setLoading());

      dispatch(updateTicketType({ type: "lokalee" }));
    }
    if (newValue === 1) {
      dispatch(updateTicketType({ ...ticketType, type: "viator" }));
    }
  };

  const refreshPage = () => {
    window.location.reload(true);
  };

  const updateTicketFn = (data) => {
    const updatedData = {
      _id: data._id,
      title: data.title,
      city: data.city,
      availability_type: data.availability_type,
      source: data.source,
      itemType: data.itemType,
      availabilities: data.availabilities,
    };
    dispatch(updateTicket(updatedData));
  };

  const detailTabs = [
    { id: 1, title: "Lokalee tickets" },
    { id: 2, title: "Viator tickets", disabled: user?.role === "partner" },
  ];

  const actionsChildFn = (rowData) => {
    let actionsChildRole = {};
    actionsChildRole = {
      icon: rowData.top ? CancelIcon : OfflinePinIcon,
      tooltip: rowData.top ? "Top false" : "Top true",

      iconProps: {
        style: {
          marginLeft: "10px",
          fontSize: "25px",
          color: rowData.top ? "#f50057" : "green",
        },
      },
      onClick: () => {
        // eslint-disable-next-line no-unused-expressions
        rowData.top ? handleFalseTop(rowData._id) : handleTopTrue(rowData._id);
        refreshPage();
      },
    };
    return actionsChildRole;
  };

  // const handleClick = (event) => {
  //   if (anchorEl && anchorEl.contains(event.target)) {
  //     setAnchorEl(null);
  //   } else {
  //     setAnchorEl(event.target);
  //   }
  // };

  // const handleRejected = (id) => {
  //   setLoading();
  //   dispatch(rejectItem(id));
  //   setTimeout(() => {
  //     tableRef.current.onQueryChange();
  //   }, 1000);
  // };

  // const handleApproved = (id) => {
  //   setLoading();
  //   dispatch(approve(id));
  //   setTimeout(() => {
  //     tableRef.current.onQueryChange();
  //   }, 1000);
  // };

  // const actionsChildFn = (rowData) => {
  //   let actionsChildRole = {};
  //   actionsChildRole = {
  //     icon:
  //       rowData.approvalStatus === "approved"
  //         ? DangerousIcon
  //         : rowData.approvalStatus === "pending"
  //         ? PendingIcon
  //         : VerifiedIcon,
  //     tooltip: rowData.approvalStatus === "approved" ? "reject" : "approve",

  //     iconProps: {
  //       style: {
  //         marginLeft: "10px",
  //         fontSize: "25px",
  //         color:
  //           rowData.approvalStatus === "approved"
  //             ? "#f50057"
  //             : rowData.approvalStatus === "pending"
  //             ? "gray"
  //             : "green",
  //       },
  //     },
  //     onClick: (event) => {
  //       if (user.role === "admin") {
  //         if (rowData.approvalStatus === "pending") {
  //           handleClick(event, rowData._id);
  //         } else if (rowData.approvalStatus === "approved") {
  //           handleRejected(rowData._id);
  //         } else {
  //           handleApproved(rowData._id);
  //         }
  //         // tableRef.current.onQueryChange();
  //       }
  //     },
  //   };
  //   return actionsChildRole;
  // };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container justifyContent="left">
        <Grid item size={{ xs: 4 }}>
          <Grid container direction="column">
            <Grid>
              {user?.role === "partner" && locationId && (
                <LocationUserPartner
                  defaultLocationId={locationId}
                  handleChange={handleChange}
                  locations={locations || {}}
                />
              )}
            </Grid>
            {user?.role !== "partner" && (
              <Grid>
                <MDBox>
                  <DynamicTabs
                    ticketPage
                    detailTabs={detailTabs}
                    userRole={user?.role}
                    tabValue={tabValue}
                    handleSetTabValue={handleSetTabValue}
                  />
                </MDBox>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item size={{ xs: 12 }}>
        {(user.role === "partner" && locationId) || user.role === "admin" ? (
          tabValue === 0 ? (
            <MaterialTablePicker
              key={tabValue}
              screen="TicketsList"
              ticketsTable
              title=""
              columns={[
                columnsMap.titleCol,
                columnsMap.tag,
                columnsMap.tagstheme,
                columnsMap.primaryDestinationWithFilter,
                columnsMap.currencyNoDefault,
                columnsMap.fromPrice,
                columnsMap.approvalStatusBool,
                columnsMap.topCol,
              ]}
              tableRef={tableRef}
              asyncTable
              sortField="title"
              onRowClickHandler="item"
              searchField="tickets"
              exportMenuField="items"
              itemType="ticket"
              isLoading={loading}
              idSynonymName="_id"
              currentUrl="tickets"
              asyncURL={
                user.role === "admin"
                  ? `${process.env.REACT_APP_API}/tickets?source=lokalee&`
                  : onChangeLocationID && user?.role === "partner"
                  ? `/v2/tickets?location=${locationId}&source=lokalee&`
                  : ticket?.location?._id &&
                    ticket?.location?._id !== locationId &&
                    user?.role === "partner"
                  ? `/v2/tickets?location=${ticket?.location?._id}&source=lokalee&`
                  : locationPartner && locationPartner !== locationId && user?.role === "partner"
                  ? `/v2/tickets?location=${locationPartner}&source=lokalee&`
                  : `/v2/tickets?location=${locationId}&source=lokalee&`
              }
              addUrl={
                user.role === "admin"
                  ? `tickets/new-ticket`
                  : `tickets/new-ticket?locationId=${locationId}`
              }
              actionsName="Ticket"
              onRowClickURL={`/${user.role}/tickets`}
              editableUpdate={updateTicketFn}
              editableDelete={deleteTicketFn}
              actionsChild={actionsChildFn}
              updateRoleAction
              deleteRoleAction
              editableRole={user.role === "admin"}
              actionsRole={user.role === "admin" || user.role === "partner"}
            />
          ) : (
            <MaterialTablePicker
              key={tabValue}
              screen="TicketsList"
              title=""
              ticketsTable
              columns={[
                columnsMap.titleCol,
                columnsMap.tag,
                columnsMap.tagstheme,
                columnsMap.primaryDestinationField,
                columnsMap.currencyWithFilter,
                columnsMap.fromPrice,
                columnsMap.approvalStatusWithFilter,
                columnsMap.topFilterDisabled,
              ]}
              tableRef={tableRef}
              asyncTable
              sortField="title"
              onRowClickHandler="item"
              searchField="tickets"
              exportMenuField="items"
              itemType="ticket"
              isLoading={loading}
              idSynonymName="_id"
              currentUrl="tickets"
              actionsChild={actionsChildFn}
              // asyncURL={`${process.env.REACT_APP_API}/tickets?source=viator&`}
              asyncURL={
                user.role === "admin"
                  ? `${process.env.REACT_APP_API}/tickets?source=viator&`
                  : onChangeLocationID && user?.role === "partner"
                  ? `/v2/tickets?location=${locationId}&source=viator&`
                  : ticket?.location?._id &&
                    ticket?.location?._id !== locationId &&
                    user?.role === "partner"
                  ? `/v2/tickets?location=${ticket?.location?._id}&source=viator&`
                  : locationPartner && locationPartner !== locationId && user?.role === "partner"
                  ? `/v2/tickets?location=${locationPartner}&source=viator&`
                  : `/v2/tickets?location=${locationId}&source=viator&`
              }
              actionsName="Ticket"
              isRowClickable={false}
              // actionsRole (no add btn for viator tickets)
            />
          )
        ) : null}
      </Grid>
      {/* {Array.isArray(itemsExp) && (
        <div>
          <Dialog
            open={open}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Delete Ticket </DialogTitle>
            <DialogContent>
              {Array.isArray(itemsExp) && (
                <>
                  <DialogContentText id="alert-dialog-description">
                    {`This ticket is found in experience(s): ${joinedTitles}.`}
                  </DialogContentText>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this ticket?
                  </DialogContentText>
                </>
              )}
            </DialogContent>
            <DialogActions>
              <MDButton
                onClick={() => {
                  dispatch(deleteTicket(rowDataTicket?._id));
                  handleCloseDialog();
                }}
              >
                Yes
              </MDButton>
              <MDButton onClick={handleCloseDialog} autoFocus>
                No
              </MDButton>
            </DialogActions>
          </Dialog>
        </div>
      )} */}
      <Footer />
    </DashboardLayout>
  );
};

export default Tickets;
